import React, { Component } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';

import preval from 'preval.macro';

import About from 'content/About';
import SimpleAddition from 'content/SimpleAddition';

import './App.scss';
import TopNav from 'components/TopNav';
import { LabeledPath } from 'model/types';
import ConvolutionSteps from './content/ConvolutionSteps/ConvolutionSteps';
import Topics from 'content/Topics';
import SumOfManySines from 'content/SumOfManySines';
import UnitCircleAndCurves from 'content/UnitCircleAndCurves/UnitCircleAndCurves';
import SineOverTime from 'content/SineOverTime';
import SoundsFromSines from './content/SoundsFromSines';

// This can be found in the firebase project console settings under General
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAEHREo6oQSvqB__CyqDVcSB9g5gj9u0kM",
  authDomain: "woundedpixels.firebaseapp.com",
  projectId: "woundedpixels",
  storageBucket: "woundedpixels.appspot.com",
  messagingSenderId: "195027317241",
  appId: "1:195027317241:web:d1cfa85b30f403ed238d28",
  measurementId: "G-XD5R11VQK2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

type Props = {};

type State = {};

const home: LabeledPath = { label: 'DSP Playground', path: '/' };
const links = [
  { label: 'Topics', path: '/topics' },
  { label: 'About', path: '/about' },
];
const navProps = { home, links };

class App extends Component<Props, State> {
  render(): JSX.Element {
    const onAnalyticsEvent = (eventKey: string) => {
      logEvent(analytics, eventKey);
    }

    const buildStamp = preval`module.exports = new Date().toLocaleString();`;
    return (
      <div className="App">
        <HashRouter basename="/">
          <TopNav {...navProps} centerText="" />
          <Switch>
            <Route path="/unit-circle">
              <UnitCircleAndCurves onAnalyticsEvent={onAnalyticsEvent}/>
            </Route>
            <Route path="/sine-over-time">
              <SineOverTime onAnalyticsEvent={onAnalyticsEvent}/>
            </Route>
            <Route path="/add-sines">
              <SimpleAddition onAnalyticsEvent={onAnalyticsEvent}/>
            </Route>
            <Route path="/sum-many-sines">
              <SumOfManySines onAnalyticsEvent={onAnalyticsEvent}/>
            </Route>
            <Route path="/sounds-from-sines">
              <SoundsFromSines onAnalyticsEvent={onAnalyticsEvent}/>
            </Route>
            <Route path="/convolution-steps">
              <ConvolutionSteps onAnalyticsEvent={onAnalyticsEvent}/>
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/topics">
              <Topics />
            </Route>
            <Route path="/">
              <Topics />
            </Route>
          </Switch>

          <TopNav
            {...navProps}
            centerText={`© 2021 - WoundedPixels.com  Last build at: ${buildStamp}`}
          />
        </HashRouter>
      </div>
    );
  }
}

export default App;
